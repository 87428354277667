@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ToggleSwitch {
    background: #F6E3D3;
    width: 46px;
    height: 12px;
    margin-top: 5px;
    border-radius: 1em;
    cursor: pointer;
}
.ToggleSwitch::before {
    content: attr('Enable');
}
.ToggleSwitch--label {
    text-align: right;
    font: 13px/18px 'Poppins';
    letter-spacing: 0.97px;
    color: #fff;
}
.ToggleSwitch .knob {
    position: relative;
    width: 24px;
    height: 24px;
    background: #8D8C8A;
    border: 1px solid #8D8C8A;
    border-radius: 50%;
    left: 0em;
    transition: left 0.3s ease-out;
    top: -6px;
}
.ToggleSwitch .knob.active {
    left: 25px;
    background: #EF882E;
    border: 1px solid #EF882E;
}

.mybtn {
    font-family: 'Poppins';
    font-style: normal;
    color: white;
    background: #F8933C;
    border-radius: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: capitalize;
    transition: .2s all ease-in-out;
    margin-bottom: 20px;
    text-decoration: none;
}
.mybtn img {
    width: auto;
    height: 14px;
    margin-left: 20px;
}
.mybtn:hover {
    background: #EF882E;
    text-decoration: unset !important;
    cursor: pointer;
    color: white;
}

.mybtn.mybtn-primary {
    background: #3C4043;
    border: 1px solid #3C4043;
    text-decoration: none !important;
}
.mybtn.mybtn-primary:hover {
    background: #1F1E1D;
    text-decoration: none !important;
    color: white;
}
.mybtn.mybtn-primary-solid {
    background: #ffff;
    border: 1px solid #ffff;
    text-decoration: none !important;
    color:#3C4043;
}
.mybtn.mybtn-primary-solid:hover {
    background: #ffff;
    text-decoration: none !important;
    color: #3C4043;
    border: 1px solid #3C4043;
}
.mybtn.mybtn-secondary {
    background: white;
    text-decoration: none !important;
}

.mybtn.mybtn-secondary:hover  {
    border: 2px solid #3C4043;
    text-decoration: none !important;
    color:#3C4043 ;
}

.mybtn.mybtn-transparent {
background: transparent;
border: 2px solid #F8933C;
text-decoration: none !important;
color: #F8933C;
}
.mybtn.mybtn-transparent:hover {
    background: transparent;
    border: 2px solid #EF882E;
    text-decoration: none !important;
    color: #EF882E;
}
.mybtn.mybtn-primary-transparent {
    background: transparent;
    border: 2px solid #3C4043;
    color:#3C4043;
    text-decoration: none !important;
    font-weight: 600;
}
.mybtn.mybtn-primary-transparent:hover {
    background: rgba(0, 0, 0, 0.05);
    border: 2px solid #1F1E1D;
    text-decoration: none !important;
    color:#1F1E1D;
}
.mybtn.mybtn-secondary-transparent {
    background: transparent;
    border: 2px solid white;
    color: white !important;
}
.mybtn-secondary-transparent:hover  {
    background: rgba(0, 0, 0, 0.205);
    border: 2px solid white;
    text-decoration: none !important;
    color: white !important;
}

/* Mobile */
@media (max-width: 480px) {
  .mybtn {
      height: 56px;
  }
}
.privacy {
    background: #fff;
    padding: 18px 0;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    z-index: 1;
    position: relative;

}

.privacy--curtain {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.945);
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(16px);
            backdrop-filter: blur(16px);
}
.privacy--wrapper {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}
.privacy--container {
    display: flex;
    justify-content: space-between;

}
.privacy--accept {
    float: right;
    margin-bottom: 0;
    margin-right: 20px;
}
 .privacy img {
    width: 24px;
 }
 .privacy hr {
    color: #EBEBEB;
 }
.privacy .closes {
    position: absolute;
    right: 25px;
    top: 5px;
}
.privacy .closes {
    margin-top: 14px;
    z-index: 1;
}
.privacy .closes .font-stroke {
    -webkit-text-stroke: 1px 3C4043;
}
.privacy .privacy--overview .privacy--title {
    padding-left: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #3C4043;
}
.privacy .privacy--overview .text-box {
    max-height: 294px;
    overflow-y: scroll;
    overflow-y: scroll;
    padding: 20px;
}
.privacy .privacy--overview .text-box::-webkit-scrollbar {
    width: 7px;
}
.privacy .privacy--overview .text-box::-webkit-scrollbar-track {
    padding-right: 5px;
    box-shadow: inset 0 0 5px #F8F8F8;
    border-radius: 10px;
}
.privacy .privacy--overview .text-box::-webkit-scrollbar-thumb {
    background: #E5E5E5;
    border-radius: 10px;
    height: 10px;
}
.privacy .privacy--overview .text-box::-webkit-scrollbar-thumb:hover {
    background: #E5E5E5;
}
.privacy .privacy--overview .text-box:focus {
    outline: 0;
}
.privacy .privacy--overview .text-box p {
    text-align: left;
    font: 12px/18px 'Poppins';
    letter-spacing: 0.9px;
    color: #3C4043;
}
.privacy .privacy--overview .policy {
    text-align: left;
    color: #EF882E;
    margin-bottom: 18px;
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    text-decoration: none;

}
.privacy .privacy--overview .policy .fa-external-link-alt {
    font-size: 12px;
    margin-left: 5px;
}
.privacy .privacy--overview .collaps-btn {
    width: 100%;
    border: 1px solid #58546f;
    border-radius: 16px;
    padding: 11px 15px;
    position: relative;
    margin-bottom: 10px;
}
.privacy .privacy--overview .collaps-btn .fas {
    color: #EF882E;
    margin-right: 10px;
}
.privacy .privacy--overview .collaps-btn label {
    text-align: left;
    font: 13px/18px 'Poppins';
    letter-spacing: 0.97px;
    color: #EF882E;
    opacity: 1;
    text-transform: capitalize;
}
.privacy .privacy--overview .collaps-btn .indicator {
    position: absolute;
    top: 13px;
    right: 15px;
    text-align: right;
    font: 13px/18px 'Poppins';
    letter-spacing: 0.97px;
    color: #3C4043;
    z-index: 10000;
}
.privacy .privacy--overview .collaps-btn .note {
    position: absolute;
    right: 54px;
    text-align: right;
    font: 13px/18px 'Poppins';
    letter-spacing: 0.97px;
    color: #3C4043;
    top: 13px;
}
.privacy .privacy--overview .collaps-btn .trigger {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
}
.privacy .privacy--overview .collaps-content {
    padding: 5px 15px;
}
.privacy .privacy--overview .collaps-content p {
    text-align: left;
    font: 12px/16px 'Poppins';
    letter-spacing: 0.9px;
    color: #3C4043;
    margin-bottom: 10px;
}
.privacy .privacy--overview .collaps-content p a {
    font-weight: normal;
    text-decoration: underline;
    color: 3C4043;
}
@media (min-width: 768px) {
    .privacy {
        width: 731px;
   }
}

@keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
.cookies {
    width: 90vw;
    padding: 22px 28px 16px 28px;
    position: fixed;
    bottom: 67px;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 20px rgba(0, 0, 0, 0.12);
    border-radius: 7px;
    margin: 0 auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    animation: 1s slideInFromBottom;
}
.cookies p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    color: #3C4043;
}
.cookies a {

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-right: 10px;
    margin-top: 20px;
    min-width: 160px;
    
    
}
.cookies h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #3C4043;
}
.cookies .btn--settings {
    text-decoration: underline !important;
    font: 600 12px/20px 'Poppins';
    letter-spacing: 0.9px;
    color: #2f2d41;
    margin-right: 20px;
}
.cookies .btn--accept {
    background-color: #f3ed62;
    border-radius: 28px;
    padding: 11px 25px;
    font: 12px/20px 'Poppins';
    letter-spacing: 0.9px;
    color: #2f2d41;
}
.cookies .btn--accept:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cookies--button_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Mobile */
@media (max-width: 480px) {
    .cookies a {
        width: 100%;
        margin-top: 0;
        margin-bottom: 10px;

    }
    .cookies p {
        margin-bottom: 28px;
    }
    .cookies--button_container {
        display: unset;
        align-items: center;
        justify-content: center;
    }
    
}
@media (max-width: 1366px) {
    /* .cookies a {
        margin:31px 10px;
    }       */
}
@media (min-width: 1738px) {
    .col-x-lg-3 {
            flex: 0 0 auto;
            width: 25%;
    }
    .col-x-lg-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    
}

.cookies .mybtn {
    font-family: 'Poppins';
    font-style: normal;
    color: white;
    background: #F8933C;
    border-radius: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: capitalize;
    transition: .2s all ease-in-out;
    margin-bottom: 20px;
    text-decoration: none;
    min-width: 160px;
    margin-left: 20px;
}
.cookies .mybtn img {
    width: auto;
    height: 14px;
    margin-left: 20px;
}
.cookies .mybtn:hover {
    background: #EF882E;
    text-decoration: unset !important;
    cursor: pointer;
    color: white;
}

.cookies .mybtn.mybtn-primary {
    background: #3C4043;
    border: 1px solid #3C4043;
    text-decoration: none !important;
}
.cookies .mybtn.mybtn-primary:hover {
    background: #1F1E1D;
    text-decoration: none !important;
    color: white;
}
.cookies .mybtn.mybtn-primary-solid {
    background: #ffff;
    border: 1px solid #ffff;
    text-decoration: none !important;
    color:#3C4043;
}
.cookies .mybtn.mybtn-primary-solid:hover {
    background: #ffff;
    text-decoration: none !important;
    color: #3C4043;
    border: 1px solid #3C4043;
}
.mybtn.mybtn-secondary {
    background: white;
    text-decoration: none !important;
}

.cookies .mybtn.mybtn-secondary:hover  {
    border: 2px solid #3C4043;
    text-decoration: none !important;
    color:#3C4043 ;
}

.cookies .mybtn.mybtn-transparent {
background: transparent;
border: 2px solid #F8933C;
text-decoration: none !important;
color: #F8933C;
}
.cookies .mybtn.mybtn-transparent:hover {
    background: transparent;
    border: 2px solid #EF882E;
    text-decoration: none !important;
    color: #EF882E;
}
.cookies .mybtn.mybtn-primary-transparent {
    background: transparent;
    border: 2px solid #3C4043;
    color:#3C4043;
    text-decoration: none !important;
    font-weight: 600;
}
.mybtn.mybtn-primary-transparent:hover {
    background: rgba(0, 0, 0, 0.05);
    border: 2px solid #1F1E1D;
    text-decoration: none !important;
    color:#1F1E1D;
}
.cookies .mybtn.mybtn-secondary-transparent {
    background: transparent;
    border: 2px solid white;
    color: white !important;
}
.cookies .cookies .mybtn-secondary-transparent:hover  {
    background: rgba(0, 0, 0, 0.205);
    border: 2px solid white;
    text-decoration: none !important;
    color: white !important;
}

/* Mobile */
@media (max-width: 480px) {
    .cookies .mybtn {
      height: 56px;
      width: 100%;
      margin-left: 0;
  }
}
